<template>
  <div class="content">
    <notice :title-left="true" label="pay_page_tips"/>
    <div class="payList">
      <div class="payItem flex_ac" :class="{ active: active === 0 }" @click="active = 0">
        <div class="circle"><img src="../assets/images/dagou.png" alt="" v-if="active === 0"></div>
        <img class="payImg" src="../assets/images/zhifubao.png" alt="">
        <span>支付宝</span>
      </div>
      <div class="payItem flex_ac" :class="{ active: active === 1 }" @click="active = 1">
        <div class="circle"><img src="../assets/images/dagou.png" alt="" v-if="active === 1"></div>
        <img class="payImg" src="../assets/images/weixin.png" alt="">
        <span>微信</span>
      </div>
    </div>
    <div class="price">订单金额：<span>￥{{ orderDetail.price }}</span></div>
    <div class="package ">
      <div class="packageName">套餐说明</div>
      <div class="packageContent">{{ orderDetail.describe }}</div>
    </div>
    <el-button class="elBtn" type="primary" @click="payCode" :loading="loading">支付</el-button>
    <div class="qrCodeMask flex_jc_ac" v-if="payshow" @click="payshow = false">
      <div id="qrcode" ref="qrcode" class="paywechat"></div>
    </div>
  </div>
</template>

<script>
import notice from '../components/Notice.vue'
import QRCode from 'qrcodejs2'

export default {
  name: 'pay',
  components: {
    notice
  },
  data() {
    return {
      active: 0,
      order_num: '',
      orderDetail: {},
      payshow: false,
      codeurl: '',
      qrcode: null,
      timer1: null,
      loading: false
    }
  },
  created() {
    this.order_num = this.$route.query.order_num
    this.getOrderDetail()
  },
  watch: {
    payshow() {
      if (!this.payshow && this.timer1) {
        clearInterval(this.timer1)
      }
    }
  },
  methods: {
    async wechatpay() {
      try {
        this.checkPayStatus()
        this.$once('hook:beforeDestroy', () => { // 如果在组件内的支付，很可能会监听不到组件销毁 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
          clearInterval(this.timer1)
          this.payshow = false // 支付的弹窗
        })
        this.$nextTick(() => { // 生成二维码
          this.qrcode = new QRCode('qrcode', {
            width: 150, // 二维码宽度
            height: 150, // 二维码高度
            text: this.codeurl, // 调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr=uDKsQ4E00"
            colorDark: '#000', // 颜色配置
            colorLight: '#fff'
          })
        })
      } catch (err) {
        this.$message.error({
          message: err,
          type: 'none'
        })
      }
    },
    checkPayStatus() {
      const that = this
      this.timer1 = setInterval(() => {
        this.$api.checkPayStatus({ order_num: this.order_num }).then(res => {
          if (res.status === 1) {
            clearInterval(that.timer1)
            that.$message({
              message: '支付订单成功！',
              type: 'success'
            })
            that.$router.replace({
              path: '/home/orderDetail',
              query: { order_num: this.order_num }
            })
          }
        })
      }, 1000)
    },
    payCode() { // 获取支付
      this.loading = true
      this.$api.getPayQrcode({ order_num: this.order_num, method: this.active ? 'wechat' : 'alipay' }).then(res => {
        this.loading = false
        if (this.active) {
          this.payshow = true
          clearInterval(this.timer1)
          // document.getElementById('qrcode').innerHTML = '' // 这句代码可以避免重复生成二维码
          this.codeurl = res.res.code_url
          this.wechatpay()
        } else {
          // console.log(res)
          this.$router.push({ path: '/alipay', query: { htmls: res.res } })
        }
      }).catch(err => {
        this.loading = false
        if (err.code === 7001) {
          this.$message({
            message: err.msg
          })
          setTimeout(() => {
            this.$router.replace({
              path: '/home/orderDetail',
              query: { order_num: this.order_num }
            })
          }, 1000)
        }
      })
    },
    getOrderDetail() {
      this.$api.getOrderDetail({ order_num: this.order_num }).then(res => {
        this.orderDetail = res.info
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.qrCodeMask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .6);
  #qrcode {
    padding: 24px;
    background-color: #fff;
  }
}
.content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px 20px;
    border: 1px solid #e3e3e3;
}
.payList {
    margin-top: 20px;
    .payItem {
        &:last-child {
          margin-top: 12px;
        }
        &.active {
          border-color: #1296db;
          .circle {
            border: unset;
          }
        }
        width: 500px;
        height: 60px;
        border: 1px solid #e3e3e3;
        border-radius: 3px;
        box-sizing: border-box;
        padding: 16px;
        cursor: pointer;
        .circle {
          width: 16px;
          height: 16px;
          border: 1px solid #e3e3e3;
          border-radius: 50%;
          img {
            width: 100%;
            left: 100%;
          }
        }
        .payImg {
          width: 20px;
          height: 20px;
          margin-left: 16px;
        }
        span {
          margin-left: 10px;
        }
    }
}
.price {
  margin-top: 20px;
  font-size: 18px;
  margin-left: 4px;
  span {
    font-weight: bold;
    color: #409EFF;
  }
}
.package {
  margin-left: 4px;
  .packageName {
    margin-top: 20px;
    font-size: 18px;
  }
  .packageContent {
    margin: 20px 0;
  }
}
.elBtn {
  width: 285px;
  height: 48px;
  margin-left: 4px;
}
</style>
